import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, combineLatest, Observable, of, take, tap } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { isNotNullOrUndefined } from '@ep/shared';
import { OrderReviewsApiService } from '../services';
import { Store } from '@ngrx/store';
import { getConsumerEntities, selectCurrentOrderReview, selectQuestionEntities } from '@app/resources/ngrx/selectors';
import { ConsumerActions, OrderReviewActions, QuestionActions } from '@app/resources/ngrx/actions';
import { OrderReview } from '@app/resources/services';
import _ from 'lodash';

@Injectable()
export class ReviewRouteResolver {
  private readonly orderReviewsApiService = inject(OrderReviewsApiService);
  private readonly router = inject(Router);
  private readonly store = inject(Store);

  resolve(route: ActivatedRouteSnapshot): Observable<OrderReview> {
    let orderReviewId = route.paramMap.get('orderReviewId') ?? '';
    this.store.dispatch(OrderReviewActions.orderReviewById({ payload: { orderReviewId } }));

    return this.store.select(selectCurrentOrderReview).pipe(
      take(1),
      switchMap((currentOrderReview) => {
        if (currentOrderReview?.OrderReviewId != orderReviewId) {
          return this.orderReviewsApiService.getReview(orderReviewId).pipe(
            tap((review) => {
              if (!review) {
                this.router.navigateByUrl('not-found');
              } else {
                this.store.dispatch(OrderReviewActions.upsertOrderReview({ payload: { review } }));
                this.store.dispatch(OrderReviewActions.orderReviewById({ payload: { orderReviewId } }));
              }
            }),
            switchMap((review) => this.getQuestionsAndConsumer$(review))
          );
        }
        this.store.dispatch(OrderReviewActions.orderReviewById({ payload: { orderReviewId } }));

        if (currentOrderReview.Questions.length === 0) return this.getQuestionsAndConsumer$(currentOrderReview);
        return of(currentOrderReview);
      }),
      filter(isNotNullOrUndefined)
    );
  }

  private getQuestionsAndConsumer$(review: OrderReview): Observable<OrderReview> {
    return combineLatest([this.store.select(selectQuestionEntities), this.store.select(getConsumerEntities)]).pipe(
      take(1),
      switchMap(([questionEntities, consumerEntities]) => {
        const questions = review.QuestionIds.map((questionId) => questionEntities[questionId]).filter(
          isNotNullOrUndefined
        );
        const consumer = consumerEntities[review.ConsumerId];

        let reviewCopy = _.cloneDeep(review);

        if (questions.length !== 0) reviewCopy.Questions = questions;
        if (isNotNullOrUndefined(consumer)) reviewCopy.Consumer = consumer;

        return of(reviewCopy).pipe(
          switchMap((review) => {
            if (isNotNullOrUndefined(consumer)) return of(review);
            return this.getConsumerForReview$(review.ConsumerId).pipe(switchMap(() => of(review)));
          }),
          switchMap((review) => {
            if (questions.length !== 0) return of(review);
            return this.getQuestionsForReview$(review.OrderReviewId).pipe(switchMap(() => of(review)));
          }),
          tap((review) =>
            this.store.dispatch(
              OrderReviewActions.upsertOrderReview({
                payload: { review },
              })
            )
          ),
          catchError((err) => {
            console.error(err);
            return of(err);
          })
        );
      })
    );
  }

  private getConsumerForReview$(consumerId: number) {
    return this.orderReviewsApiService.getConsumerForReview(consumerId).pipe(
      tap((consumer) =>
        this.store.dispatch(
          ConsumerActions.upsertConsumer({
            payload: { consumer },
          })
        )
      )
    );
  }

  private getQuestionsForReview$(reviewId: string) {
    return this.orderReviewsApiService.getQuestionsForReview(reviewId).pipe(
      tap((questions) =>
        this.store.dispatch(
          QuestionActions.upsertManyQuestionSuccess({
            payload: { questions },
          })
        )
      )
    );
  }
}
