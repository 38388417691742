import { Employee, EmployeeResponse } from '@app/resources/services/employee.service';
import { EmployeeAccessTypes } from '@shared/enums';
import { EmployeeAccessTypeOption } from '@app/areas/admin/submodules/employees/models';
import {
  DeviceAdminOption,
  FullAdminOption,
  LocationAdminOption,
} from '@app/areas/admin/submodules/employees/utils/constants';
import { DeviceAdmin, FullAdmin, LocationAdmin } from '@shared/utils';

export function mapToEmployee(data: EmployeeResponse): Employee {
  return {
    EmployeeId: data.employeeId,
    NameFirst: data.nameFirst,
    NameLast: data.nameLast,
    EmailAddress: data.emailAddress,
    PhoneNumber: data.phoneNumber,
    Pin: data.pin,
    AccessType: data.accessType,
  };
}

export function mapEmployeeAccessTypeToAccessTypeOption(accessType: EmployeeAccessTypes): EmployeeAccessTypeOption {
  switch (accessType) {
    case FullAdmin:
      return FullAdminOption;
    case LocationAdmin:
      return LocationAdminOption;
    case DeviceAdmin:
    default:
      return DeviceAdminOption;
  }
}
