import { Input, Pipe } from '@angular/core';

@Pipe({
  name: 'formatPhone',
  standalone: true,
})
export class FormatPhonePipe {
  transform(inputVal?: string | null) {
    if (!inputVal) return '';
    if (inputVal.length < 11) return inputVal;

    const regionCode = inputVal.slice(0, 1);
    const areaCodeStr = inputVal.slice(1, 4);
    const midSectionStr = inputVal.slice(4, 7);
    const lastSectionStr = inputVal.slice(7, 11);

    return `+${regionCode} (${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`;
  }
}
