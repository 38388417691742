import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { catchError, of } from 'rxjs';
import { OrderPaymentStatusTypes } from '@shared/enums';
import { OrderReview, OrderReviewsResponse } from '@app/resources/services/order-review.service';
import { Employee } from '@app/resources/services/employee.service';
import { Consumer } from '@app/resources/services/consumer.service';

export interface OrderResponse {
  orderId: number;
  orderGuid: string;
  employeeId: number;
  statusType: number;
  enrollmentType: number;
  amountTotal: number;
  startDateTime: Date | string;
  reviewIds: {
    $values: string[];
  };
  orderPayments: OrderPaymentsResponse;
}

export interface OrderPaymentResponse {
  orderPaymentId: number;
  orderPaymentGuid: string;
  statusType: OrderPaymentStatusTypes;
  amountTotal: number;
  amountGratuity: number;
  amountTax: number;
  amountTendered: number;
  amountDiscount: number;
  reward: number;
  rewardRedeemed: number;
  points: number;
  pointsRedeemed: number;
  startDateTime: Date | string;
  orderId: number;
  consumerId: number | null;
}

export interface OrderPaymentsResponse {
  $values: OrderPaymentResponse[];
}

export interface OrdersResponse {
  orders: { $values: OrderResponse[] };
  totalTransactions: number;
}

export interface Order {
  OrderId: number;
  OrderGuid: string;
  EmployeeId: number;
  Employee?: Employee | null;
  StatusType: number;
  EnrollmentType: number;
  AmountTotal: number;
  StartDateTime: Date | string;
  ReviewIds: string[];
  Reviews: OrderReview[];
  OrderPayments: OrderPayment[];
}

export interface OrderPayment {
  OrderPaymentId: number;
  OrderPaymentGuid: string;
  StatusType: OrderPaymentStatusTypes;
  AmountTotal: number;
  AmountGratuity: number;
  AmountTax: number;
  AmountTendered: number;
  AmountDiscount: number;
  Reward: number;
  RewardRedeemed: number;
  Points: number;
  PointsRedeemed: number;
  StartDateTime: Date | string;
  OrderId: number;
  Order?: Order | null;
  ConsumerId: number | null;
  Consumer?: Consumer | null;
}

@Injectable({
  providedIn: 'root',
})
export class OrderService extends BaseApiService {
  private readonly orderEndpoint = '/v2/Orders';

  findAll(pageNumber: number, pageSize: number) {
    return this.get<OrdersResponse>(`${this.orderEndpoint}`, { pageNumber: pageNumber, pageSize: pageSize }).pipe(
      catchError((err) => {
        console.error(err);
        const emptyOrdersResponse: OrdersResponse = { orders: { $values: [] }, totalTransactions: 0 };
        return of(emptyOrdersResponse);
      })
    );
  }

  findUnique(orderId: number) {
    return this.get<OrderResponse>(`${this.orderEndpoint}/${orderId}`);
  }
}
