import {
  Report,
  DailySalesReportResponse,
  DailySalesReport,
  DailySalesReportsResponse,
  ReportResponse,
  ReportsResponse,
} from '@app/resources/services';
import { isNotNullOrUndefined } from '@ep/shared';

export const mapToReport = (data: ReportResponse): Report => {
  return {
    ReportId: data.reportId,
    NamePrivate: data.namePrivate,
    Display: typeof data.display === 'string' ? JSON.parse(data.display)['en-US'] : data.display['en-US'],
    StatusType: data.statusType,
    ReportContextType: data.reportContextType,
  };
};

export const mapToReports = (data: ReportsResponse): Report[] => {
  return data.$values.map(mapToReport);
};

export const mapToDailySalesReport = (data: DailySalesReportResponse): DailySalesReport => {
  return {
    Metric: data.metric,
    Count: data.count,
    Amount: data.amount,
  };
};

export const mapToDailySalesReports = (data: DailySalesReportsResponse): DailySalesReport[] => {
  return data.$values.map(mapToDailySalesReport);
};
