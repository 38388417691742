import {
  ApplicationProfileState,
  AuthenticationState,
  ConsumerState,
  CredentialsState,
  DeviceState,
  EmployeeState,
  InvoiceState,
  LoyaltyProgramState,
  OrderState,
  OrderReviewState,
  QuestionState,
  RouterStateUrl,
  ReportState,
} from '@app/resources/ngrx/states';
import { RouterReducerState } from '@ngrx/router-store';

const DEFAULT_ENTITY = {
  ids: [],
  entities: {},
};

export interface RootState {
  applicationProfile: ApplicationProfileState;
  authentication: AuthenticationState;
  consumer: ConsumerState;
  credentials: CredentialsState;
  device: DeviceState;
  employee: EmployeeState;
  invoice: InvoiceState;
  loyaltyProgram: LoyaltyProgramState;
  order: OrderState;
  orderReview: OrderReviewState;
  questions: QuestionState;
  report: ReportState;
  router: RouterReducerState<RouterStateUrl>;
}

export const DEFAULT_ROOT_STATE: RootState = {
  applicationProfile: {},
  authentication: { loggedIn: false, showTermsAndConditions: false, error: null },
  consumer: { ...DEFAULT_ENTITY, selectedConsumerId: 0, hasLoaded: false },
  credentials: { clientAccessId: null, jwt: null },
  device: { ...DEFAULT_ENTITY, selectedDeviceId: 0, hasLoaded: false },
  employee: { ...DEFAULT_ENTITY, selectedEmployeeId: 0, hasLoaded: false },
  invoice: { ...DEFAULT_ENTITY, selectedPastInvoiceId: 0, hasLoaded: false },
  loyaltyProgram: DEFAULT_ENTITY,
  order: { ...DEFAULT_ENTITY, selectedOrderId: null, hasLoaded: false, orderQty: 0 },
  orderReview: { ...DEFAULT_ENTITY, selectedOrderReviewId: null, hasLoaded: false },
  questions: { ...DEFAULT_ENTITY, selectedQuestionId: 0, hasLoaded: false },
  report: { ...DEFAULT_ENTITY, selectedReportId: 0, dailySalesReport: { date: null, reports: [] } },
  router: {
    state: {
      url: '',
      queryParams: {},
      params: {},
    },
    navigationId: 0,
  },
};
