<p-card class="existing-transaction-detail-display-card" data-cy="existing-transaction-detail-display-card">
  <ng-template pTemplate="header">
    <div id="existing-transaction-detail-display-card-header">
      <div id="existing-transaction-detail-display-card-header-title">
        <h1>Order #{{ transaction().OrderGuid | truncateGuid }}</h1>
      </div>
    </div>
  </ng-template>
  <div class="existing-transaction-details">
    <div class="existing-transaction-details-column-0">
      <app-existing-transaction-basic-details [transaction]="transaction()" />
      <app-existing-transaction-payment-details [payment]="payment()" />
    </div>
    <div class="existing-transaction-details-column-1" *ngIf="payment() && payment().Consumer">
      <app-existing-transaction-consumer-details [consumer]="payment().Consumer" />
    </div>
  </div>
</p-card>
