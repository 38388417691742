import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DailySalesReport, Report } from '@app/resources/services';

enum ReportActionTypes {
  LOAD_REPORTS = 'Load Reports',
  LOAD_REPORTS_SUCCESS = 'Load Reports Success',
  LOAD_REPORTS_ERROR = 'Load Reports Error',
  SELECT_REPORT = 'Report By Id',
  UPDATE_DAILY_SALES_REPORTS = 'Update Daily Sales Reports',
}

export const ReportActions = createActionGroup({
  source: 'Report',
  events: {
    [ReportActionTypes.LOAD_REPORTS]: emptyProps(),
    [ReportActionTypes.LOAD_REPORTS_SUCCESS]: props<{
      payload: { reports: Report[] };
    }>(),
    [ReportActionTypes.LOAD_REPORTS_ERROR]: props<{ payload: { error: string } }>(),
    [ReportActionTypes.SELECT_REPORT]: props<{ payload: { reportId: number } }>(),
    [ReportActionTypes.UPDATE_DAILY_SALES_REPORTS]: props<{
      payload: { date: Date | string; reports: DailySalesReport[] };
    }>(),
  },
});
