// Angular Core Imports
import { Component, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';

// Third-Party Imports
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

// Shared and Application-Specific Imports
import { EpTableComponent, EPTableConfig } from '@ep/shared';
import { selectOrderReviewsSortedByCreatedDate } from '@app/resources/ngrx/selectors';
import {
  getOrderReviewStatusSeverity,
  mapOrderReviewStatusToValue,
  mapToReviewTableDisplays,
} from '@app/areas/reviews/utils/mappers';
import { OrderReviewModuleActions } from '@app/areas/reviews/store';
import { ReviewTableDisplay } from '@app/areas/reviews/models/review-table-display.model';

@Component({
  selector: 'app-order-reviews-table',
  standalone: true,
  imports: [EpTableComponent, AsyncPipe],
  template: `
    <div class="p-4">
      <app-ep-table [config]="config" [data]="orderReviews$ | async" data-cy="order-reviews-table" />
    </div>
  `,
})
export class OrderReviewsTableComponent {
  private readonly store = inject(Store);

  protected config: EPTableConfig<ReviewTableDisplay> = {
    header: 'reviews',
    pagination: {
      rows: 10,
      showCurrentPageReport: true,
    },
    columns: [
      {
        header: 'Create Date',
        field: 'CreatedDateTime',
        columnType: 'date',
        dateFormat: 'MM/dd/YYYY',
        defaultValue: 'Missing date',
      },
      {
        header: 'Name',
        field: 'Consumer',
        columnType: 'string',
        defaultValue: 'VALUED CUSTOMER',
      },
      {
        header: 'Score',
        field: 'Score',
        columnType: 'categorical',
        defaultValue: 'Poor',
        fieldToValueMap: mapOrderReviewStatusToValue,
        severity: getOrderReviewStatusSeverity,
      },
      {
        header: 'Last Updated',
        field: 'UpdateDateTime',
        columnType: 'date',
        dateFormat: 'MM/dd/YYYY',
        defaultValue: 'Missing date',
      },
    ],
    style: {},
    globalFilterFields: ['Name'],
    actions: {
      rowClick: (orderReview: ReviewTableDisplay) => {
        this.store.dispatch(
          OrderReviewModuleActions.navigateExistingOrderReview({
            payload: { orderReviewId: orderReview.OrderReviewId },
          })
        );
      },
    },
  };

  protected orderReviews$ = this.store
    .select(selectOrderReviewsSortedByCreatedDate)
    .pipe(map(mapToReviewTableDisplays));
}
