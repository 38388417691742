import {
  Consumer,
  ConsumerLoyaltyProgram,
  ConsumerLoyaltyProgramResponse,
  ConsumerRelationship,
  ConsumerRelationshipResponse,
  ConsumerResponse,
  ConsumersResponse,
  Order,
  OrderPayment,
  OrderPaymentResponse,
  OrderPaymentsResponse,
  OrderResponse,
  OrderReview,
  OrderReviewResponse,
  OrderReviewsResponse,
} from '@app/resources/services';
import { ConsumerTableDisplay } from '@app/areas/members/models/consumer-table-display.model';

export const mapToConsumers = (data: ConsumersResponse): Consumer[] => {
  return data.$values.map(mapToConsumer);
};

export const mapToConsumerLoyaltyProgram = (data: ConsumerLoyaltyProgramResponse): ConsumerLoyaltyProgram => {
  return {
    ConsumerLoyaltyProgramId: data.consumerLoyaltyProgramId,
    ConsumerId: data.consumerId,
    MerchantId: data.merchantId,
    Name: data.name,
    AverageSpend: data.averageSpend,
    LifetimeSpend: data.lifetimeSpend,
    TotalVisits: data.totalVisits,
    RewardBalance: data.rewardBalance,
    PointsBalance: data.pointsBalance,
  };
};

export const mapToConsumerRelationship = (data: ConsumerRelationshipResponse): ConsumerRelationship => {
  return {
    ConsumerRelationshipId: data.consumerRelationshipId,
    ConsumerId: data.consumerId,
    MerchantId: data.merchantId,
    TotalOrderCount: data.totalOrderCount,
    TotalSpendAmount: data.totalSpendAmount,
    AssignDateTime: data.assignDateTime,
    LastSeenDateTime: data.lastSeenDateTime,
  };
};

export const mapToConsumerOrderPayments = (data: OrderPaymentsResponse): OrderPayment[] => {
  return data.$values.map(mapToConsumerOrderPayment);
};

export const mapToOrderReviews = (data: OrderReviewsResponse): OrderReview[] => {
  return data.$values.map(mapToOrderReview);
};

export const mapToOrderReview = (data: OrderReviewResponse): OrderReview => {
  return {
    OrderReviewId: data.orderReviewId,
    ConsumerId: data.consumerId,
    Score: data.score,
    CreatedDateTime: data.createdDateTime,
    UpdateDateTime: data.updateDateTime,
    AmountTotal: data.amountTotal,
    QuestionIds: data.questionIds.$values,
    Questions: [],
  };
};

export const mapToConsumerOrderPayment = (data: OrderPaymentResponse): OrderPayment => {
  return {
    OrderPaymentId: data.orderPaymentId,
    OrderPaymentGuid: data.orderPaymentGuid,
    AmountTotal: data.amountTotal,
    AmountGratuity: data.amountGratuity,
    AmountTax: data.amountTax,
    AmountTendered: data.amountTendered,
    AmountDiscount: data.amountDiscount,
    Reward: data.reward,
    RewardRedeemed: data.rewardRedeemed,
    Points: data.points,
    PointsRedeemed: data.pointsRedeemed,
    StatusType: data.statusType,
    StartDateTime: data.startDateTime,
    OrderId: data.orderId,
    ConsumerId: data.consumerId,
  };
};

export const mapToConsumer = (data: ConsumerResponse): Consumer => {
  return {
    ConsumerId: data.consumerId,
    NameFirst: data.nameFirst,
    NameLast: data.nameLast,
    PhoneNumber: data.phoneNumber,
    EmailAddress: data.emailAddress,
    ConsumerRelationship: data.consumerRelationship ? mapToConsumerRelationship(data.consumerRelationship) : null,
    LoyaltyProgram: data.loyaltyProgram ? mapToConsumerLoyaltyProgram(data.loyaltyProgram) : null,
    OrderPayments: data.orderPayments ? mapToConsumerOrderPayments(data.orderPayments) : undefined,
  };
};

export const mapToConsumerTableDisplay = (data: Consumer): ConsumerTableDisplay => {
  return {
    ConsumerId: data.ConsumerId,
    NameFirst: data.NameFirst,
    NameLast: data.NameLast,
    LastSeenDateTime: data.ConsumerRelationship ? new Date(data.ConsumerRelationship?.LastSeenDateTime) : null,
  };
};

export const mapToConsumerTableDisplays = (data: Consumer[] | null): ConsumerTableDisplay[] | null => {
  return data ? data.map(mapToConsumerTableDisplay) : null;
};
