import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { OrderPayment, OrderPaymentsResponse } from '@app/resources/services/order.service';

export interface ConsumerLoyaltyProgramResponse {
  consumerLoyaltyProgramId: number;
  consumerId: number;
  merchantId: number;
  name: string;
  averageSpend: number;
  lifetimeSpend: number;
  totalVisits: number;
  rewardBalance: number;
  pointsBalance: number;
}

export interface ConsumerRelationshipResponse {
  consumerRelationshipId: number;
  consumerId: number;
  merchantId: number;
  totalOrderCount: number;
  totalSpendAmount: number;
  assignDateTime: number;
  lastSeenDateTime: number;
}

export interface ConsumerResponse {
  consumerId: number;
  nameFirst: string;
  nameLast: string;
  phoneNumber: string;
  emailAddress: string;
  consumerRelationship: ConsumerRelationshipResponse | null;
  loyaltyProgram: ConsumerLoyaltyProgramResponse | null;
  orderPayments: OrderPaymentsResponse;
}

export interface ConsumersResponse {
  $values: ConsumerResponse[];
}

export interface ConsumerLoyaltyProgram {
  ConsumerLoyaltyProgramId: number;
  ConsumerId: number;
  MerchantId: number;
  Name: string;
  AverageSpend: number;
  LifetimeSpend: number;
  TotalVisits: number;
  RewardBalance: number;
  PointsBalance: number;
}

export interface ConsumerRelationship {
  ConsumerRelationshipId: number;
  ConsumerId: number;
  MerchantId: number;
  TotalOrderCount: number;
  TotalSpendAmount: number;
  AssignDateTime: number;
  LastSeenDateTime: number;
}

export interface Consumer {
  ConsumerId: number;
  NameFirst: string;
  NameLast: string;
  PhoneNumber?: string;
  EmailAddress?: string;
  ConsumerRelationship: ConsumerRelationship | null;
  LoyaltyProgram?: ConsumerLoyaltyProgram | null;
  OrderPayments?: OrderPayment[];
}

@Injectable({
  providedIn: 'root',
})
export class ConsumerService extends BaseApiService {
  private readonly consumerEndpoint = '/v2/Consumers';

  findAll() {
    return this.get<ConsumersResponse>(`${this.consumerEndpoint}`);
  }

  findUnique(consumerId: number) {
    return this.get<ConsumerResponse>(`${this.consumerEndpoint}/${consumerId}`);
  }
}
