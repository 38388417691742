import { inject, Injectable } from '@angular/core';
import {
  Consumer,
  ConsumerService,
  Employee,
  EmployeeService,
  Order,
  OrderResponse,
  OrderReview,
  OrderReviewService,
  OrderService,
} from '@app/resources/services';
import { catchError, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { mapToOrder, mapToOrders } from '@app/areas/transactions/utils';
import { mapToConsumer, mapToOrderReview } from '@app/areas/members/utils';
import { mapToEmployee } from '@app/areas/admin/submodules/employees/utils';

@Injectable()
export class TransactionApiService {
  private readonly consumerService = inject(ConsumerService);
  private readonly employeeService = inject(EmployeeService);
  private readonly orderService = inject(OrderService);
  private readonly orderReviewService = inject(OrderReviewService);

  getTransactions(pageNumber: number, pageSize: number): Observable<{ orders: Order[]; totalTransactions: number }> {
    return this.orderService.findAll(pageNumber, pageSize).pipe(
      map((data) => ({
        orders: mapToOrders(data),
        totalTransactions: data.totalTransactions,
      }))
    );
  }

  getTransaction(orderId: number): Observable<Order> {
    return this.orderService.findUnique(orderId).pipe(
      map(mapToOrder),
      catchError((err) => {
        console.error(err);
        return of(err);
      })
    );
  }

  getConsumerForTransaction(consumerId: number): Observable<Consumer> {
    return this.consumerService.findUnique(consumerId).pipe(map(mapToConsumer));
  }

  getReviewForTransaction(orderReviewId: string): Observable<OrderReview> {
    return this.orderReviewService.findUnique(orderReviewId).pipe(map(mapToOrderReview));
  }

  getEmployeeForTransaction(employeeId: number): Observable<Employee> {
    return this.employeeService.findUnique(employeeId).pipe(map(mapToEmployee));
  }

  getEmployeesForTransactions(): Observable<Employee[]> {
    return this.employeeService
      .findAll()
      .pipe(map((response) => (response ?? { $values: [] }).$values.map(mapToEmployee)));
  }
}
