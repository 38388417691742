import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrderReviewState } from '@app/resources/ngrx/states';
import { isNotNullOrUndefined } from '@ep/shared';
import { selectOrderReviewEntities } from '@app/resources/ngrx/adapters';
import { selectQuestionEntities } from '@app/resources/ngrx/selectors/question.selector';
import { getConsumerEntities } from '@app/resources/ngrx/selectors/consumer.selector';

export const selectOrderReviewState = createFeatureSelector<OrderReviewState>('orderReview');
export const getOrderReviewEntities = createSelector(selectOrderReviewState, selectOrderReviewEntities);

export const getSelectedOrderReviewId = (state: OrderReviewState) => {
  return state.selectedOrderReviewId;
};
export const selectCurrentOrderReviewId = createSelector(selectOrderReviewState, getSelectedOrderReviewId);

export const hasReviewsLoaded = createSelector(selectOrderReviewState, (state) => state.hasLoaded);

export const selectOrderReviews = createSelector(
  getOrderReviewEntities,
  getConsumerEntities,
  selectQuestionEntities,
  hasReviewsLoaded,
  (orderReviewEntities, consumerEntities, questionEntities, hasLoaded) => {
    if (!hasLoaded) return null;
    return Object.values(orderReviewEntities)
      .filter(isNotNullOrUndefined)
      .map((review) => {
        return {
          ...review,
          Consumer: consumerEntities[review.ConsumerId] ?? null,
          Questions: review.QuestionIds.map((questionId) => questionEntities[questionId]).filter(isNotNullOrUndefined),
        };
      });
  }
);

export const selectOrderReviewsSortedByCreatedDate = createSelector(
  getOrderReviewEntities,
  getConsumerEntities,
  hasReviewsLoaded,
  (orderReviewEntities, consumerEntities, hasLoaded) => {
    if (!hasLoaded) return null;

    return Object.values(orderReviewEntities)
      .filter(isNotNullOrUndefined)
      .sort((a, b) => {
        const reviewCreatedDate1 = new Date(a.CreatedDateTime);
        const reviewCreatedDate2 = new Date(b.CreatedDateTime);
        return reviewCreatedDate2.getTime() - reviewCreatedDate1.getTime();
      })
      .map((review) => ({
        ...review,
        Consumer: consumerEntities[review.ConsumerId] ?? null,
      }));
  }
);

export const selectCurrentOrderReview = createSelector(
  getOrderReviewEntities,
  getConsumerEntities,
  selectQuestionEntities,
  selectCurrentOrderReviewId,
  (orderReviewEntities, consumerEntities, questionEntities, orderReviewId) => {
    if (!orderReviewId) return null;
    const review = orderReviewEntities[orderReviewId];
    if (!isNotNullOrUndefined(review)) return null;
    return {
      ...review,
      Consumer: consumerEntities[review.ConsumerId] ?? null,
      Questions: review.QuestionIds.map((questionId) => questionEntities[questionId]).filter(isNotNullOrUndefined),
    };
  }
);

export const selectCurrentOrderReviewQuestions = createSelector(
  selectQuestionEntities,
  selectCurrentOrderReview,
  (questionEntities, review) => {
    if (!isNotNullOrUndefined(review)) return [];

    return review.QuestionIds.map((questionId) => questionEntities[questionId]);
  }
);
