<p-panel
  header="Details"
  class="transaction-basic-details-display-card"
  data-cy="transaction-basic-details-display-card"
>
  <div class="transaction-basic-details-display">
    <div class="transaction-basic-details-display-column transaction-basic-details-display-row-0"></div>
    <div class="transaction-basic-details-display-column transaction-basic-details-display-row-1">
      <div class="transaction-basic-details">
        <div class="transaction-basic-details-row">
          <label>Sale Date</label>
          <span> {{ transaction().StartDateTime | date : 'MM/dd/YYYY, hh:mm aa' }} </span>
        </div>
        <div class="transaction-basic-details-row">
          <label>Employee</label>
          <span> {{ employee() }} </span>
        </div>
      </div>
    </div>
  </div>
</p-panel>
