import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Order } from '@app/resources/services';

enum OrderActionTypes {
  UPSERT_ORDER = 'Upsert Order',
  LOAD_ORDERS = 'Load Orders',
  LOAD_ORDERS_SUCCESS = 'Load Orders Success',
  LOAD_ORDERS_ERROR = 'Load Orders Error',
  SELECT_ORDER = 'Order By Id',
}

export const OrderActions = createActionGroup({
  source: 'Order',
  events: {
    [OrderActionTypes.UPSERT_ORDER]: props<{ payload: { order: Order } }>(),
    [OrderActionTypes.LOAD_ORDERS]: emptyProps(),
    [OrderActionTypes.LOAD_ORDERS_SUCCESS]: props<{
      payload: { orders: Order[]; totalTransactions: number };
    }>(),
    [OrderActionTypes.LOAD_ORDERS_ERROR]: props<{ payload: { error: string } }>(),
    [OrderActionTypes.SELECT_ORDER]: props<{ payload: { orderId: number } }>(),
  },
});
