import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, tap, withLatestFrom } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { EmployeeActions, OrderActions, RouterActions } from '@app/resources/ngrx/actions';
import { TransactionApiService } from '@app/areas/transactions/services';
import { TransactionModuleActions } from '@app/areas/transactions/store/transaction-module.actions';
import { selectEmployees } from '@app/resources/ngrx/selectors';

@Injectable()
export class TransactionModuleEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly transactionsApiService = inject(TransactionApiService);

  loadTransactions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrderActions.loadOrders),
        withLatestFrom(this.store.select(selectEmployees)),
        switchMap(([action, employees]) => {
          if (!employees?.length)
            return this.transactionsApiService.getEmployeesForTransactions().pipe(
              tap((employees) =>
                this.store.dispatch(
                  EmployeeActions.upsertEmployeesSuccess({
                    payload: { employees },
                  })
                )
              ),
              switchMap(() => {
                return this.transactionsApiService.getTransactions(1, 10).pipe(
                  map(({ orders, totalTransactions }) => {
                    return OrderActions.loadOrdersSuccess({ payload: { orders, totalTransactions } });
                  }),

                  catchError((error: { message: string }) =>
                    of(OrderActions.loadOrdersError({ payload: { error: error.message } }))
                  )
                );
              })
            );

          return this.transactionsApiService.getTransactions(1, 10).pipe(
            map(({ orders, totalTransactions }) => {
              return OrderActions.loadOrdersSuccess({ payload: { orders, totalTransactions } });
            }),
            catchError((error: { message: string }) =>
              of(OrderActions.loadOrdersError({ payload: { error: error.message } }))
            )
          );
        })
      ),
    { dispatch: true }
  );

  navigateToExistingTransactionForm = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TransactionModuleActions.navigateExistingTransaction),
        tap(({ payload }) => {
          this.store.dispatch(
            RouterActions.navigateByUrlWithLocation({
              payload: {
                path: `transactions/${payload.orderId}`,
              },
            })
          );
        })
      ),
    {
      dispatch: false,
    }
  );
}
