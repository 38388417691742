import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCurrentOrder } from '@app/resources/ngrx/selectors';
import { ExistingTransactionDetailsCardComponent } from '@app/areas/transactions/components/existing-transaction-details-card/existing-transaction-details-card.component';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-existing-transaction',
  standalone: true,
  imports: [ExistingTransactionDetailsCardComponent, AsyncPipe, NgIf],
  template: `
    <div *ngIf="transactions$ | async; let transaction" class="existing-transaction-wrapper">
      <app-existing-transaction-details-card [transaction]="transaction" />
    </div>
  `,
  styleUrl: './existing-transaction.component.scss',
})
export class ExistingTransactionComponent {
  private readonly store = inject(Store);
  transactions$ = this.store.select(selectCurrentOrder);
}
