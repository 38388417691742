// Angular Core Imports
import { Component, computed, input, Signal } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';

// Third-Party Imports
import { AvatarModule } from 'primeng/avatar';
import { PanelModule } from 'primeng/panel';

// Application-Specific Imports
import { Consumer } from '@app/resources/services';

@Component({
  selector: 'app-existing-member-information',
  standalone: true,
  imports: [AvatarModule, DatePipe, CurrencyPipe, PanelModule],
  templateUrl: './existing-member-information.component.html',
  styleUrl: './existing-member-information.component.scss',
})
export class ExistingMemberInformationComponent {
  member = input.required<Consumer>();

  protected avatarInitials: Signal<string> = computed(() => {
    const nameFirst = this.member().NameFirst;
    const nameLast = this.member().NameLast;

    return nameFirst && nameLast ? `${nameFirst.substring(0, 1)}${nameLast.substring(0, 1)}` : 'N/A';
  });

  protected recentOrderPayment = computed(() => {
    const orderPayments = [...this.member().OrderPayments!].sort((op1, op2) => {
      const date1 = new Date(op1.StartDateTime);
      const date2 = new Date(op2.StartDateTime);
      return date2.getTime() - date1.getTime();
    });
    return orderPayments.length > 0 ? orderPayments[0] : null;
  });
}
