// Angular Core Imports
import { Component, inject } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

// Third-Party Imports
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { PrimeTemplate } from 'primeng/api';
import { Store } from '@ngrx/store';

// RxJS Operators
import { filter } from 'rxjs/operators';

// Application-Specific Imports
import { FONT_AWESOME_ICONS, isNotNullOrUndefined } from '@ep/shared';
import { OrderReviewQuestion } from '@app/resources/services';
import { selectCurrentOrderReviewQuestions } from '@app/resources/ngrx/selectors';

@Component({
  selector: 'app-existing-order-review-questions-table',
  standalone: true,
  imports: [
    AsyncPipe,
    InputTextModule,
    NgIf,
    OverlayPanelModule,
    PrimeTemplate,
    ReactiveFormsModule,
    TableModule,
    ToggleButtonModule,
    FaIconComponent,
  ],
  templateUrl: './existing-order-review-questions-table.component.html',
  styleUrl: './existing-order-review-questions-table.component.scss',
})
export class ExistingOrderReviewQuestionsTableComponent {
  private readonly store = inject(Store);
  protected questions$ = this.store.select(selectCurrentOrderReviewQuestions).pipe(filter(isNotNullOrUndefined));

  protected icons = FONT_AWESOME_ICONS;
  protected columns = ['Question', 'Answer'];
  protected isThumbsUp(question: OrderReviewQuestion) {
    return question.Score === 5;
  }
}
