// Angular Core Modules
import { Component, inject } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';

// Third Party Modules
import { Store } from '@ngrx/store';

// Application Modules
import { selectCurrentConsumer } from '@app/resources/ngrx/selectors';
import { ExistingMemberDisplayCardComponent } from '@app/areas/members/components/existing-member-display-card/existing-member-display-card.component';

@Component({
  selector: 'app-existing-member',
  standalone: true,
  imports: [ExistingMemberDisplayCardComponent, AsyncPipe, NgIf],
  template: `
    <div *ngIf="member$ | async; let member" class="existing-member-wrapper">
      <app-existing-member-display-card [member]="member" class="w-100" />
    </div>
  `,
  styleUrl: './existing-member.component.scss',
})
export class ExistingMemberComponent {
  private readonly store = inject(Store);

  protected member$ = this.store.select(selectCurrentConsumer);
}
