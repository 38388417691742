import { Component, computed, input, OnInit } from '@angular/core';
import { ExistingTransactionConsumerDetailsComponent } from '@app/areas/transactions/components/existing-transaction-consumer-details/existing-transaction-consumer-details.component';
import { ExistingTransactionPaymentDetailsComponent } from '@app/areas/transactions/components/existing-transaction-payment-details/existing-transaction-payment-details.component';
import { CardModule } from 'primeng/card';
import { NgIf } from '@angular/common';
import { TruncateGuidPipe } from '@shared/pipes';
import { Order } from '@app/resources/services';
import { ExistingTransactionBasicDetailsComponent } from '@app/areas/transactions/components/existing-transaction-basic-details/existing-transaction-basic-details.component';

@Component({
  selector: 'app-existing-transaction-details-card',
  standalone: true,
  imports: [
    ExistingTransactionConsumerDetailsComponent,
    ExistingTransactionPaymentDetailsComponent,
    CardModule,
    TruncateGuidPipe,
    NgIf,
    ExistingTransactionBasicDetailsComponent,
  ],
  templateUrl: './existing-transaction-details-card.component.html',
  styleUrl: './existing-transaction-details-card.component.scss',
})
export class ExistingTransactionDetailsCardComponent {
  transaction = input.required<Order>();

  payment = computed(() => {
    return this.transaction().OrderPayments[0];
  });
}
