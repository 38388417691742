// Angular Core Imports
import { Component, computed, input } from '@angular/core';
import { AsyncPipe, CurrencyPipe, DatePipe } from '@angular/common';

// Third-Party Imports
import { CardModule } from 'primeng/card';
import { PrimeTemplate } from 'primeng/api';

// Shared and Application-Specific Imports
import { isNotNullOrUndefined } from '@ep/shared';
import { OrderReview } from '@app/resources/services';
import { OrderReviewIdDisplayPipe } from '@app/areas/reviews/pipes';
import { EmployeeFormCardFooterComponent } from '@app/areas/admin/submodules/employees/components/employee-form-card-footer/employee-form-card-footer.component';
import { EmployeeFormCardHeaderComponent } from '@app/areas/admin/submodules/employees/components/employee-form-card-header/employee-form-card-header.component';
import { EmployeeFormComponent } from '@app/areas/admin/submodules/employees/forms';

@Component({
  selector: 'app-order-review-display-card',
  standalone: true,
  imports: [
    CardModule,
    EmployeeFormCardFooterComponent,
    EmployeeFormCardHeaderComponent,
    EmployeeFormComponent,
    PrimeTemplate,
    CurrencyPipe,
    AsyncPipe,
    OrderReviewIdDisplayPipe,
    DatePipe,
  ],
  templateUrl: './order-review-display-card.component.html',
  styleUrl: './order-review-display-card.component.scss',
})
export class OrderReviewDisplayCardComponent {
  review = input.required<OrderReview>();

  consumer = computed(() => {
    const consumer = this.review().Consumer;
    return isNotNullOrUndefined(consumer) ? `${consumer.NameFirst} ${consumer.NameLast}` : 'N/A';
  });
}
