<p-card class="member-display-card" data-cy="member-display-card">
  <ng-template pTemplate="header">
    <div id="member-display-card-header">
      <div id="member-display-card-header-title">
        <h1>{{ member().NameFirst }} {{ member().NameLast }}</h1>
      </div>
    </div>
  </ng-template>
  <div class="member-display">
    <div class="member-display-column member-display-column-0">
      <app-existing-member-information [member]="member()" class="full-height-width" />
    </div>
    <div class="member-display-column member-display-column-1" *ngIf="member().OrderPayments!.length > 0">
      <app-existing-member-recent-transactions-list
        [orderPayments]="member().OrderPayments!"
        class="full-height-width"
      />
    </div>
  </div>
</p-card>
