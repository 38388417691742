import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConsumerState, OrderState } from '@app/resources/ngrx/states';
import { isNotNullOrUndefined } from '@ep/shared';
import { selectConsumerEntities, selectOrderEntities } from '@app/resources/ngrx/adapters';
import { selectEmployeeEntities } from '@app/resources/ngrx/selectors/employee.selector';

export const selectOrderState = createFeatureSelector<OrderState>('order');

const getConsumerEntities = createSelector(createFeatureSelector<ConsumerState>('consumer'), selectConsumerEntities);

export const getOrderEntities = createSelector(selectOrderState, selectOrderEntities);

export const hasOrdersLoaded = createSelector(selectOrderState, (state) => state.hasLoaded);

export const getSelectedOrderId = (state: OrderState) => {
  return state.selectedOrderId;
};
export const selectCurrentOrderId = createSelector(selectOrderState, getSelectedOrderId);

export const selectOrders = createSelector(
  getOrderEntities,
  selectEmployeeEntities,
  hasOrdersLoaded,
  (orderEntities, employeeEntities, hasLoaded) => {
    if (!hasLoaded) return null;
    return Object.values(orderEntities)
      .filter(isNotNullOrUndefined)
      .map((order) => {
        return {
          ...order,
          Employee: employeeEntities[order.EmployeeId],
        };
      });
  }
);

export const selectOrdersSortedByStartDate = createSelector(
  getOrderEntities,
  selectEmployeeEntities,
  hasOrdersLoaded,
  (orderEntities, employeeEntities, hasLoaded) => {
    if (!hasLoaded) return null;
    return Object.values(orderEntities)
      .filter(isNotNullOrUndefined)
      .map((order) => {
        return {
          ...order,
          Employee: employeeEntities[order.EmployeeId],
        };
      })
      .sort((a, b) => {
        const orderStartDate1 = new Date(a.StartDateTime);
        const orderStartDate2 = new Date(b.StartDateTime);
        return orderStartDate2.getTime() - orderStartDate1.getTime();
      });
  }
);
export const selectCurrentOrder = createSelector(
  getOrderEntities,
  selectEmployeeEntities,
  getConsumerEntities,
  selectCurrentOrderId,
  (orderEntities, employeeEntities, consumerEntities, orderId) => {
    if (!isNotNullOrUndefined(orderId)) return null;
    const order = orderEntities[orderId];
    if (!isNotNullOrUndefined(order)) return null;

    return {
      ...order,
      Employee: employeeEntities[order.EmployeeId] ?? null,
      OrderPayments: order.OrderPayments.map((op) => {
        return isNotNullOrUndefined(op.ConsumerId)
          ? {
              ...op,
              Consumer: consumerEntities[op.ConsumerId],
            }
          : op;
      }),
    };
  }
);

export const selectOrderQty = createSelector(selectOrderState, (state) => state.orderQty);
