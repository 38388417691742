import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { catchError, of } from 'rxjs';
import { EnumDictionary } from '@shared/types';
import { I18NLanguageEnum } from '@app/i18n';
import { Question } from '@app/resources/services/question.service';
import { Consumer } from '@app/resources/services/consumer.service';

export type OrderReviewQuestionDisplay = EnumDictionary<I18NLanguageEnum, string>;

export interface OrderReviewQuestionResponse {
  orderReviewQuestionId: number;
  orderReviewId: string;
  questionId: number;
  display: OrderReviewQuestionDisplay | string;
  score: number;
}

export interface OrderReviewResponse {
  orderReviewId: string;
  consumerId: number;
  score?: number | null;
  createdDateTime: Date | string;
  updateDateTime: Date | string;
  amountTotal: number;
  questionIds: {
    $values: number[];
  };
}

export interface OrderReviewsResponse {
  $values: OrderReviewResponse[];
}

export interface OrderReviewQuestion {
  OrderReviewQuestionId: number;
  OrderReviewId: string;
  QuestionId: number;
  Display: string;
  Score: number;
}

export interface OrderReview {
  OrderReviewId: string;
  ConsumerId: number;
  Consumer?: Consumer | null;
  Score?: number | null;
  CreatedDateTime: Date | string;
  UpdateDateTime: Date | string;
  AmountTotal: number;
  QuestionIds: number[];
  Questions: Question[];
}

@Injectable({
  providedIn: 'root',
})
export class OrderReviewService extends BaseApiService {
  private readonly orderReviewEndpoint = '/v2/OrderReviews';

  findAll() {
    return this.get<OrderReviewsResponse>(`${this.orderReviewEndpoint}`).pipe(
      catchError((err) => {
        console.error(err);
        const emptyOrderReviewsResponse: OrderReviewsResponse = { $values: [] };
        return of(emptyOrderReviewsResponse);
      })
    );
  }

  findUnique(orderReviewId: string) {
    return this.get<OrderReviewResponse>(`${this.orderReviewEndpoint}/${orderReviewId}`);
  }
}
