import { Component, computed, input } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FormatPhonePipe } from '@shared/pipes';
import { CurrencyPipe, DatePipe, NgIf } from '@angular/common';
import { PanelModule } from 'primeng/panel';
import { Order } from '@app/resources/services';

@Component({
  selector: 'app-existing-transaction-basic-details',
  standalone: true,
  imports: [FaIconComponent, FormatPhonePipe, NgIf, PanelModule, CurrencyPipe, DatePipe],
  templateUrl: './existing-transaction-basic-details.component.html',
  styleUrl: './existing-transaction-basic-details.component.scss',
})
export class ExistingTransactionBasicDetailsComponent {
  transaction = input.required<Order>();

  employee = computed(() => {
    const employee = this.transaction().Employee;
    return employee ? `${employee.NameFirst} ${employee.NameLast}` : 'EMPLOYEE';
  });
}
