<p-panel header="Orders" class="existing-member-recent-display-card" data-cy="existing-member-recent-display-card">
  <p-dataView
    #dv
    *ngIf="orderPayments$ | async; let orderPayments"
    [value]="orderPayments"
    [rows]="4"
    [paginator]="true"
  >
    <ng-template pTemplate="list" let-transactions>
      <div class="grid grid-nogutter mx-3">
        <div class="col-12" *ngFor="let item of transactions; let first = first">
          <div
            class="flex flex-column sm:flex-row sm:align-items-center p-3 gap-3"
            [ngClass]="{ 'border-top-1 surface-border ': !first }"
          >
            <div class="flex flex-column md:flex-row justify-content-between md:align-items-center flex-1 gap-4">
              <div class="order-details-row">
                <div>
                  <span class="font-medium text-secondary text-xl">
                    Order #{{ item?.Order?.OrderGuid | truncateGuid }}
                  </span>
                  <div class="text-xl font-medium text-900 mt-2">
                    {{ item.AmountTotal | currency }}
                  </div>
                </div>
                <div class="surface-100" style="border-radius: 30px">
                  <div
                    class="surface-0 flex align-items-center gap-2 justify-content-center py-1 px-2"
                    style="
                      border-radius: 30px;
                      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
                    "
                  >
                    <span *ngIf="isThumbsUp(item)" class="member-transactions-answer-value">
                      <fa-icon [icon]="icons['faThumbsUp']" class="member-transactions-thumbs-up" />
                    </span>
                    <span *ngIf="!isThumbsUp(item)" class="member-transactions-answer-value">
                      <fa-icon [icon]="icons['faThumbsDown']" class="member-transactions-thumbs-down" />
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex flex-column md:align-items-end gap-3">
                <span class="text-xl font-semibold text-900">
                  {{ item.StartDateTime | date : 'MM/dd/YYYY, hh:mm aa' }}
                </span>
                <div class="flex flex-row-reverse md:flex-row">
                  <p-button
                    class="flex-auto md:flex-initial white-space-nowrap"
                    label="View Order"
                    size="small"
                    (click)="navigateToOrder(item)"
                  >
                    <fa-icon [icon]="icons['faCreditCard']" class="mr-3" />
                  </p-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>
</p-panel>
