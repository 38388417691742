import { EntityState } from '@ngrx/entity';
import { DailySalesReport, Report } from '@app/resources/services';

export interface ReportState extends EntityState<Report> {
  selectedReportId: number | null;
  dailySalesReport: {
    date: Date | string | null;
    reports: DailySalesReport[];
  };
}
