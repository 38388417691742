import { Component, inject, input, OnInit } from '@angular/core';
import { SimpleTextControlComponent } from '@ep/shared';
import { Button, ButtonDirective } from 'primeng/button';
import { Store } from '@ngrx/store';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ReportModuleActions } from '@app/areas/reports/store';
import { AsyncPipe, CurrencyPipe, NgForOf, NgIf, UpperCasePipe } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PrimeTemplate } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  selectCurrentReport,
  selectDailySalesReportDate,
  selectDailySalesReports,
} from '@app/resources/ngrx/selectors';
import { keys } from 'lodash';
import { DailySalesReport, Report } from '@app/resources/services';
import { CalendarModule } from 'primeng/calendar';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-existing-report-daily-summary-table',
  standalone: true,
  imports: [
    SimpleTextControlComponent,
    Button,
    AsyncPipe,
    ButtonDirective,
    InputTextModule,
    NgIf,
    OverlayPanelModule,
    PaginatorModule,
    PrimeTemplate,
    ReactiveFormsModule,
    TableModule,
    ToggleButtonModule,
    UpperCasePipe,
    CalendarModule,
    NgForOf,
    CurrencyPipe,
  ],
  templateUrl: './existing-report-daily-summary-table.component.html',
  styleUrl: './existing-report-daily-summary-table.component.scss',
})
export class ExistingReportDailySummaryTableComponent {
  private readonly store = inject(Store);

  report = toSignal(this.store.select(selectCurrentReport), { initialValue: null });

  readonly dailySalesReports = toSignal(this.store.select(selectDailySalesReports), { initialValue: [] });
  readonly dailySalesDate = toSignal(
    this.store.select(selectDailySalesReportDate).pipe(
      map((searchDate) => {
        if (searchDate === null) return null;
        const convertedDate = new Date(searchDate);
        return `${convertedDate.getMonth() + 1}/${convertedDate.getDate()}/${convertedDate.getUTCFullYear()}`;
      })
    ),
    { initialValue: null }
  );

  readonly columns: Array<keyof DailySalesReport> = ['Metric', 'Count', 'Amount'];
  readonly globalFilterFields: Array<keyof DailySalesReport> = ['Metric'];

  readonly SMALL_SCREEN_BREAKPOINT = 1024;
  protected isSmallScreen: boolean = false;

  protected reportDateControl = new FormControl<Date | string>(
    this.dailySalesDate()?.toString() ?? new Date().add({ day: -1 }),
    {
      validators: [Validators.required],
      nonNullable: true,
    }
  );

  searchDailySales() {
    const searchDate = new Date(this.reportDateControl.value);
    this.store.dispatch(ReportModuleActions.searchForReportByDate({ payload: { searchDate } }));
  }
}
