import { Component } from '@angular/core';
import { ReportsTableComponent } from '@app/areas/reports/components';

@Component({
  standalone: true,
  template: '<app-reports-table></app-reports-table>',
  styleUrls: ['./reports.component.scss'],
  imports: [ReportsTableComponent],
})
export class ReportsComponent {}
