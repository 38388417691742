import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { catchError, combineLatest, Observable, of, take, tap } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { isNotNullOrUndefined } from '@ep/shared';
import { Store } from '@ngrx/store';
import {
  getConsumerEntities,
  getOrderReviewEntities,
  selectCurrentOrder,
  selectEmployeeEntities,
} from '@app/resources/ngrx/selectors';
import {
  ConsumerActions,
  EmployeeActions,
  OrderActions,
  OrderReviewActions,
  RouterActions,
} from '@app/resources/ngrx/actions';
import { TransactionApiService } from '@app/areas/transactions/services';
import { Order } from '@app/resources/services';
import _ from 'lodash';

@Injectable()
export class TransactionRouteResolver {
  private readonly transactionApiService = inject(TransactionApiService);
  private readonly store = inject(Store);

  resolve(route: ActivatedRouteSnapshot): Observable<Order> {
    let orderId = parseInt(route.paramMap.get('orderId') ?? '');
    if (isNaN(orderId)) this.store.dispatch(RouterActions.navigateByUrl({ payload: { path: 'not-found' } }));
    this.store.dispatch(OrderActions.orderById({ payload: { orderId } }));

    return this.store.select(selectCurrentOrder).pipe(
      take(1),
      switchMap((currentOrder) => {
        if (currentOrder?.OrderId != orderId) {
          return this.transactionApiService.getTransaction(orderId).pipe(
            tap((order) => {
              if (!order) {
                this.store.dispatch(RouterActions.navigateByUrl({ payload: { path: 'not-found' } }));
              } else {
                this.store.dispatch(OrderActions.upsertOrder({ payload: { order } }));
                this.store.dispatch(OrderActions.orderById({ payload: { orderId } }));
              }
            }),
            switchMap((order) => {
              return this.getConsumerAndReviewsAndEmployee$(order);
            })
          );
        }
        this.store.dispatch(OrderActions.orderById({ payload: { orderId } }));

        // If either Consumer or Reviews is missing, the data might not be in the store yet
        if (this.isConsumerOnOrder(currentOrder) || currentOrder.Reviews?.length === 0) {
          return this.getConsumerAndReviewsAndEmployee$(currentOrder);
        }
        return of(currentOrder);
      }),
      filter(isNotNullOrUndefined)
    );
  }

  private getConsumerAndReviewsAndEmployee$(currentOrder: Order) {
    // Get current store to check what's available
    return combineLatest([
      this.store.select(getConsumerEntities),
      this.store.select(getOrderReviewEntities),
      this.store.select(selectEmployeeEntities),
    ]).pipe(
      take(1),
      switchMap(([consumerEntities, reviewEntities, employeeEntities]) => {
        const consumerId = currentOrder.OrderPayments[0].ConsumerId ?? 0;
        const consumer = consumerEntities[consumerId];
        const reviews = currentOrder.ReviewIds.map((reviewId) => reviewEntities[reviewId]).filter(isNotNullOrUndefined);
        const employee = employeeEntities[currentOrder.EmployeeId];

        // Create a shallow copy of the Order to update
        let orderCopy: Order = _.cloneDeep(currentOrder);

        if (isNotNullOrUndefined(employee)) orderCopy.Employee = employee;
        if (isNotNullOrUndefined(consumer)) orderCopy.OrderPayments[0].Consumer = consumer; // Update consumer if it exists in the store
        if (reviews.length !== 0) orderCopy.Reviews = [reviews[0]]; // Update review if it exists in the store

        return of(orderCopy).pipe(
          switchMap((order) => {
            if (isNotNullOrUndefined(employee)) return of(order);
            return this.getEmployeeForTransaction$(currentOrder.EmployeeId).pipe(switchMap(() => of(order)));
          }),
          switchMap((order) => {
            if (isNotNullOrUndefined(consumer) || consumerId === 0) return of(order);
            return this.getConsumerForTransaction$(consumerId).pipe(switchMap(() => of(order)));
          }),
          switchMap((order) => {
            if (reviews.length > 0) return of(order);
            return this.getReviewForTransaction$(currentOrder.ReviewIds[0]).pipe(switchMap(() => of(order)));
          }),
          tap((order) => this.store.dispatch(OrderActions.upsertOrder({ payload: { order } }))),
          catchError((err) => {
            console.error(err);
            return of(err);
          })
        );
      })
    );
  }

  private isConsumerOnOrder(order: Order) {
    return !!order.OrderPayments.find((op) => !!op.ConsumerId);
  }

  private getConsumerForTransaction$(consumerId: number) {
    return this.transactionApiService.getConsumerForTransaction(consumerId).pipe(
      tap((consumer) => {
        this.store.dispatch(ConsumerActions.upsertConsumer({ payload: { consumer } }));
      })
    );
  }

  private getReviewForTransaction$(reviewId: string) {
    return this.transactionApiService.getReviewForTransaction(reviewId).pipe(
      tap((review) => {
        this.store.dispatch(OrderReviewActions.upsertOrderReview({ payload: { review } }));
      })
    );
  }

  private getEmployeeForTransaction$(employeeId: number) {
    return this.transactionApiService.getEmployeeForTransaction(employeeId).pipe(
      tap((employee) => {
        this.store.dispatch(EmployeeActions.upsertEmployeeSuccess({ payload: { employee } }));
      })
    );
  }
}
