import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, of, take, tap } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ReportActions, RouterActions } from '@app/resources/ngrx/actions';
import { ReportsApiService } from '@app/areas/reports/services';
import { ReportModuleActions } from '@app/areas/reports/store/report-module.actions';

@Injectable()
export class ReportModuleEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly reportsApiService = inject(ReportsApiService);

  loadReports = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportActions.loadReports),
        switchMap(() => {
          return this.reportsApiService.getReports().pipe(
            map((reports) => ReportActions.loadReportsSuccess({ payload: { reports } })),
            catchError((error: { message: string }) =>
              of(ReportActions.loadReportsError({ payload: { error: error.message } }))
            )
          );
        })
      ),
    { dispatch: true }
  );

  navigateToExistingReportForm = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportModuleActions.navigateExistingReport),
        tap(({ payload }) => {
          this.store.dispatch(
            RouterActions.navigateByUrlWithLocation({
              payload: {
                path: `reports/${payload.reportId}`,
              },
            })
          );
        })
      ),
    {
      dispatch: false,
    }
  );

  searchDailySalesReport = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportModuleActions.searchForReportByDate),
      exhaustMap(({ payload }) => {
        return this.reportsApiService.searchForReportData(payload.searchDate).pipe(
          take(1),
          map((reports) =>
            ReportActions.updateDailySalesReports({
              payload: { date: payload.searchDate, reports },
            })
          )
        );
      })
    )
  );
}
