import { createActionGroup, props } from '@ngrx/store';

/*
 * Report Module Actions
 */
enum ReportModuleActionTypes {
  NAVIGATE_EXISTING_REPORT = 'Navigate Existing Report',
  SEARCH_FOR_REPORT = 'Search For Report By Date',
}

export const ReportModuleActions = createActionGroup({
  source: 'Report Module',
  events: {
    [ReportModuleActionTypes.NAVIGATE_EXISTING_REPORT]: props<{ payload: { reportId: number } }>(),
    [ReportModuleActionTypes.SEARCH_FOR_REPORT]: props<{ payload: { searchDate: Date } }>(),
  },
});
