import { Question, QuestionResponse } from '@app/resources/services';

export function mapToQuestion(data: QuestionResponse): Question {
  if (typeof data.display === 'string') {
    return {
      QuestionId: data.questionId,
      Display: JSON.parse(data.display)['en-US'],
      StatusType: data.statusType,
    };
  } else {
    return {
      QuestionId: data.questionId,
      Display: data.display['en-US'],
      StatusType: data.statusType,
    };
  }
}
