import { inject, Injectable } from '@angular/core';
import { DailySalesReport, ReportService } from '@app/resources/services';
import { map } from 'rxjs/operators';
import { mapToDailySalesReports, mapToReports } from '@app/areas/reports/utils/mappers';
import { BehaviorSubject, tap } from 'rxjs';

@Injectable()
export class ReportsApiService {
  private readonly reportService = inject(ReportService);

  getReports() {
    return this.reportService.findAll().pipe(map(mapToReports));
  }

  searchForReportData(searchDate: Date) {
    return this.reportService.findDailySales(searchDate).pipe(map(mapToDailySalesReports));
  }
}
