import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'truncateGuid',
})
export class TruncateGuidPipe implements PipeTransform {
  transform(guid: string): string {
    return guid?.slice(-12);
  }
}
