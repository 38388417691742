import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, shareReplay, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IframeManagerService {
  private messageSubject = new Subject<MessageEvent>();
  private iframeSrcSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private shouldShowIframeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    window.addEventListener('message', (event) => {
      this.messageSubject.next(event);
    });
  }

  get iframeSrc$(): Observable<string> {
    return this.iframeSrcSubject.asObservable();
  }

  get shouldShowIframe$(): Observable<boolean> {
    return this.shouldShowIframeSubject.asObservable();
  }

  onMessageReceived(): Observable<MessageEvent> {
    return this.messageSubject.asObservable().pipe(shareReplay(1));
  }

  setIframeSrc(src: string): void {
    this.iframeSrcSubject.next(src);
  }

  setShouldShowIframe(shouldShowIframe: boolean): void {
    this.shouldShowIframeSubject.next(shouldShowIframe);
  }

  generateIframeSrc(
    merchantPortalUrlLegacy: string,
    locationId: number,
    primaryPath: string,
    primaryPathId?: string
  ): string {
    const path = mapPathToIframeSrc(primaryPath);
    if (!path) {
      return '';
    }

    let iframeSrc = `${merchantPortalUrlLegacy}/#L${locationId}`;

    if (path !== 'admin') {
      iframeSrc = `${iframeSrc}/${path}${!!primaryPathId ? `/${primaryPathId}` : ''}`;
    } else if (primaryPath === 'admin') {
      iframeSrc = !!primaryPathId ? `${iframeSrc}/${primaryPathId}` : `${iframeSrc}/${path}`;
    }

    return iframeSrc;
  }
}

function mapPathToIframeSrc(path: string): string {
  if (path.includes('locations')) {
    return 'Locations';
  } else {
    throw new Error('Issue with mapping path to iframe source');
  }
}
