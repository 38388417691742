<p-panel
  header="Payment Summary"
  class="transaction-payment-details-display-card"
  data-cy="transaction-payment-details-display-card"
>
  <div class="transaction-payment-details-display">
    <div class="transaction-payment-details-display-column transaction-payment-details-display-row-0"></div>
    <div class="transaction-payment-details-display-column transaction-payment-details-display-row-1">
      <div class="transaction-payment-details">
        <div class="transaction-payment-details-row">
          <label>Subtotal</label>
          <span>{{ payment() !== undefined ? (payment().AmountTotal | currency) : (0 | currency) }}</span>
        </div>
        <div class="transaction-payment-details-row" *ngIf="payment() && payment().RewardRedeemed">
          <label>Reward</label>
          <span>-{{ payment().RewardRedeemed | currency }}</span>
        </div>
        <div class="transaction-payment-details-row" *ngIf="payment() && payment().AmountDiscount">
          <label>Discount</label>
          <span>{{ payment().AmountDiscount }}</span>
        </div>
        <div class="transaction-payment-details-row" *ngIf="payment() && payment().AmountGratuity">
          <label>Gratuity</label>
          <span>{{ payment().AmountGratuity | currency }}</span>
        </div>
        <div class="transaction-payment-details-row" *ngIf="payment() && payment().AmountTax">
          <label>Tax</label>
          <span>{{ payment() !== undefined ? (payment().AmountTax | currency) : (0 | currency) }}</span>
        </div>
        <div class="transaction-payment-details-row">
          <label><b>Total Paid</b></label>
          <span>{{ payment() !== undefined ? (payment().AmountTendered | currency) : (0 | currency) }}</span>
        </div>
      </div>
    </div>
  </div>
</p-panel>
