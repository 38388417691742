// Angular Core Imports
import { Component, inject } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';

// Third-Party Imports
import { Store } from '@ngrx/store';

// Application-Specific Imports
import { selectCurrentOrderReview } from '@app/resources/ngrx/selectors';
import { ExistingOrderReviewQuestionsTableComponent } from '@app/areas/reviews/components/existing-order-review-questions-table/existing-order-review-questions-table.component';
import { OrderReviewDisplayCardComponent } from '@app/areas/reviews/components/order-review-display-card/order-review-display-card.component';

@Component({
  selector: 'app-existing-order-review',
  standalone: true,
  imports: [ExistingOrderReviewQuestionsTableComponent, OrderReviewDisplayCardComponent, AsyncPipe, NgIf],
  template: `
    <div *ngIf="review$ | async; let review" class="existing-order-review-wrapper">
      <app-order-review-display-card [review]="review" />
      <app-existing-order-review-questions-table />
    </div>
  `,
  styleUrl: './existing-order-review.component.scss',
})
export class ExistingOrderReviewComponent {
  private readonly store = inject(Store);

  protected review$ = this.store.select(selectCurrentOrderReview);
}
