import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { catchError, Observable, of } from 'rxjs';
import { I18NLanguageEnum } from '@app/i18n';
import { EnumDictionary } from '@shared/types';

export type ReportDisplay = EnumDictionary<I18NLanguageEnum, string>;

export interface ReportResponse {
  reportId: number;
  namePrivate: string;
  display: ReportDisplay | string;
  statusType: number;
  reportContextType: number;
}

export interface ReportsResponse {
  $values: ReportResponse[];
}

export interface Report {
  ReportId: number;
  NamePrivate: string;
  Display: string;
  StatusType: number;
  ReportContextType: number;
}

export interface DailySalesReport {
  Metric: string;
  Count?: number | null;
  Amount: number;
}

export interface DailySalesReportResponse {
  metric: string;
  count?: number | null;
  amount: number;
}

export interface DailySalesReportsResponse {
  $values: DailySalesReportResponse[];
}

@Injectable()
export class ReportService extends BaseApiService {
  private readonly reportEndpoint = '/v2/Reports';

  findAll(): Observable<ReportsResponse> {
    return this.get<ReportsResponse>(`${this.reportEndpoint}`);
  }

  findDailySales(searchDate: Date): Observable<DailySalesReportsResponse> {
    return this.get<DailySalesReportsResponse>(`${this.reportEndpoint}/daily-sales`, {
      searchDate: searchDate.toLocaleDateString(),
    });
  }
}
