import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { ReportsComponent } from './reports.component';
import { AccessType } from '@app/@shared/enums/access-types.enum';
import { AccessGuard } from '@app/@shared/guards/access.guard';
import { ExistingReportComponent } from '@app/areas/reports/components';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: ':locationId/reports',
      component: ReportsComponent,
      canActivate: [AccessGuard],
      data: { title: 'Reports', requiredAccessTypes: [AccessType.ReportAdmin] },
    },
    {
      path: ':locationId/reports/:reportId',
      component: ExistingReportComponent,
      canActivate: [AccessGuard],
      data: { title: 'Reports', requiredAccessTypes: [AccessType.ReportAdmin] },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportsRoutingModule {}
