import { EmployeeAccessTypeOption } from '@app/areas/admin/submodules/employees/models';
import { DeviceAdmin, FullAdmin, LocationAdmin } from '@shared/utils';

export const FullAdminOption: EmployeeAccessTypeOption = {
  key: 'full-admin',
  name: 'Full Admin',
  value: FullAdmin,
};

export const LocationAdminOption: EmployeeAccessTypeOption = {
  key: 'location-admin',
  name: 'Location Admin',
  value: LocationAdmin,
};

export const DeviceAdminOption: EmployeeAccessTypeOption = {
  key: 'device-admin',
  name: 'Device Admin',
  value: DeviceAdmin,
};

export const EmployeeAccessTypeOptions: EmployeeAccessTypeOption[] = [
  DeviceAdminOption,
  LocationAdminOption,
  FullAdminOption,
];

export const EmployeeAccessTypeTooltipOptions = {
  ['full-admin']:
    'Grants top-level employee related permissions (i.e. employee and invoices) and includes Location and Device Admin permissions.',
  ['location-admin']:
    'Grants location related permissions (e.g., locations, orders, offers, reports, etc.) and includes Device Admin permissions.',
  ['device-admin']: 'Grants basic, device related permissions, including devices and pay at table.',
};
