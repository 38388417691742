<div class="daily-sales-report-module-table">
  <div class="shadow-2">
    <p-table
      id="daily-sale-report-table"
      [columns]="columns"
      [value]="dailySalesReports()"
      styleClass="p-datatable-striped"
      [globalFilterFields]="globalFilterFields"
      [filterDelay]="0"
      data-cy="daily-sales-report-module-table"
    >
      <ng-template pTemplate="caption">
        <div class="daily-sales-report-module-table-header-wrapper">
          <div class="daily-sales-report-module-table-header-title" data-cy="daily-sales-report-module-table-header">
            <h2>{{ report()?.NamePrivate ?? 'Report' }}</h2>
          </div>
          <div
            *ngIf="!isSmallScreen; else smallTableHeaderActions"
            class="daily-sales-report-module-table-header-actions"
          >
            <div class="daily-sales-report-module-table-search" data-cy="daily-sales-report-module-table-search">
              <span class="p-input-icon-left ml-auto flex">
                <p-calendar
                  [formControl]="reportDateControl"
                  [iconDisplay]="'input'"
                  [showIcon]="true"
                  inputId="icondisplay"
                />
              </span>
            </div>
            <div>
              <button
                pButton
                label="Get Report"
                class="p-button-outlined"
                icon="pi pi-chart-line"
                (click)="searchDailySales()"
                data-cy="daily-sales-report-module-table-new-button"
              ></button>
            </div>
          </div>
          <ng-template #smallTableHeaderActions>
            <div
              class="overlay-daily-sales-report-module-table-header-actions"
              data-cy="daily-sales-report-module-table-header-small-actions"
            >
              <p-overlayPanel #op>
                <div class="daily-sales-report-module-table-search-small">
                  <div
                    class="daily-sales-report-module-table-search"
                    data-cy="daily-sales-report-module-table-search-small"
                  >
                    <span class="p-input-icon-left ml-auto flex">
                      <p-calendar
                        [formControl]="reportDateControl"
                        [iconDisplay]="'input'"
                        [showIcon]="true"
                        inputId="icondisplay"
                      />
                    </span>
                  </div>
                  <div>
                    <button
                      pButton
                      label="Get Report"
                      class="p-button-outlined"
                      icon="pi pi-chart-line"
                      (click)="searchDailySales()"
                      data-cy="daily-sales-report-module-table-small-new-button"
                    ></button>
                  </div>
                </div>
              </p-overlayPanel>
              <p-button
                (click)="op.toggle($event)"
                icon="pi pi-bars"
                data-cy="daily-sales-report-module-table-overlay"
              ></p-button>
            </div>
          </ng-template>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr class="daily-sales-report-module-table-row">
          <th *ngFor="let col of columns" class="daily-sales-report-column">
            {{ col }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <ng-container>
          <tr class="daily-sales-report-module-table-row">
            <td class="daily-sales-report-module-table-row-cell-0">
              <span class="daily-sales-report-module-table-row-column-value">{{ rowData['Metric'] }}</span>
            </td>
            <td class="daily-sales-report-module-table-row-cell-1">
              <span class="daily-sales-report-module-table-row-column-value">{{ rowData['Count'] ?? 0 }}</span>
            </td>
            <td class="daily-sales-report-module-table-row-cell-2">
              <span class="daily-sales-report-module-table-row-column-value">{{ rowData['Amount'] }}</span>
            </td>
          </tr>
        </ng-container>
      </ng-template>
      <ng-template pTemplate="loadingicon">
        <div class="daily-sale-report-table-loading-icon">
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </div>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="columns.length">Search date to generate report results</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
