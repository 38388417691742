<p-panel
  header="Details"
  class="existing-member-information-display-card"
  data-cy="existing-member-information-display-card"
>
  <div class="existing-member-information-card">
    <div class="member-info-display-row">
      <label><b>MEMBER SINCE</b></label>
      <span> {{ member().ConsumerRelationship?.AssignDateTime | date }} </span>
    </div>
    <div class="member-info-display-row">
      <label><b>RECENT ACTIVITY</b></label>
      <div class="consumer-loyalty-program-panel-row">
        <label> Last Visit </label>
        <span> {{ member().ConsumerRelationship?.LastSeenDateTime | date }} </span>
      </div>
      <div class="consumer-loyalty-program-panel-row">
        <label> Amount Spent </label>
        <span> {{ recentOrderPayment()?.AmountTotal ?? 0 | currency }}</span>
      </div>
    </div>
    <div class="member-info-display-row">
      <label><b>LOYALTY</b></label>
      <div class="consumer-loyalty-program-panel-row">
        <label>Reward Balance</label>
        <span>{{ member().LoyaltyProgram?.RewardBalance ?? 0 | currency }}</span>
      </div>
      <div class="consumer-loyalty-program-panel-row">
        <label>Lifetime Spend</label>
        <span>{{ member().LoyaltyProgram?.LifetimeSpend ?? 0 | currency }}</span>
      </div>
      <div class="consumer-loyalty-program-panel-row">
        <label>Average Spend</label>
        <span>{{ member().LoyaltyProgram?.AverageSpend ?? 0 | currency }}</span>
      </div>
      <div class="consumer-loyalty-program-panel-row">
        <label>Total Visits</label>
        <span>{{ member().LoyaltyProgram?.TotalVisits ?? 0 }}</span>
      </div>
    </div>
  </div>
</p-panel>
