import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReportState } from '@app/resources/ngrx/states';
import { isNotNullOrUndefined } from '@ep/shared';
import { selectReportEntities } from '@app/resources/ngrx/adapters';

export const selectReportState = createFeatureSelector<ReportState>('report');
export const getReportEntities = createSelector(selectReportState, selectReportEntities);

export const getSelectedReportId = (state: ReportState) => {
  return state.selectedReportId;
};
export const selectCurrentReportId = createSelector(selectReportState, getSelectedReportId);

export const selectReports = createSelector(getReportEntities, (reportEntities) => {
  return Object.values(reportEntities).filter(isNotNullOrUndefined);
});
export const selectCurrentReport = createSelector(
  getReportEntities,
  selectCurrentReportId,
  (reportEntities, reportId) => {
    return reportEntities[reportId ?? 0];
  }
);

export const selectDailySalesReportDate = createSelector(selectReportState, (state) => state.dailySalesReport.date);

export const selectDailySalesReports = createSelector(selectReportState, (state) => state.dailySalesReport.reports);
