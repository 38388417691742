<p-panel
  header="Customer"
  class="transaction-consumer-details-display-card"
  data-cy="transaction-consumer-details-display-card"
>
  <div *ngIf="consumer(); let consumer" class="transaction-consumer-details-display">
    <div
      class="transaction-consumer-details-display-column transaction-consumer-details-display-row-0"
      (click)="navigateToConsumer(consumer)"
    >
      <div class="transaction-consumer-details-display-row-0-row-0">
        <div>
          <!-- <p-avatar [label]="avatarInitials()" styleClass="mr-2" size="large" shape="circle" /> -->
          <span> {{ name() }} </span>
        </div>
        <i class="pi pi-angle-right" style="font-size: 1.5rem"></i>
      </div>
      <div class="surface-100 transaction-consumer-details-display-row-0-row-1" style="border-radius: 30px">
        <div class="surface-0 transaction-consumer-details-order-icon py-1 px-2">
          <span class="member-transactions-answer-value">
            <fa-icon [icon]="icons[faFileInvoiceDollar]" class="member-transactions-thumbs-up"></fa-icon>
          </span>
        </div>
        <span> {{ consumer.OrderPayments?.length }} Orders</span>
      </div>
    </div>
    <div class="transaction-consumer-details-display-column transaction-consumer-details-display-row-1">
      <label><b>Contact Information</b></label>
      <div>
        <div *ngIf="consumer.EmailAddress">
          <fa-icon [icon]="icons[faSquareEnvelope]"></fa-icon>
          <span>{{ consumer.EmailAddress }}</span>
        </div>
        <div *ngIf="consumer.PhoneNumber">
          <fa-icon [icon]="icons[faSquarePhone]"></fa-icon>
          <span>{{ consumer.PhoneNumber | formatPhone }}</span>
        </div>
      </div>
    </div>
  </div>
</p-panel>
