import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Consumer,
  ConsumerService,
  OrderReview,
  OrderReviewService,
  Question,
  QuestionService,
} from '@app/resources/services';
import { mapToOrderReview, mapToOrderReviews } from '@app/areas/reviews/utils/mappers';
import { mapToQuestion } from '@app/areas/admin/submodules/questions/utils';
import { mapToConsumer, mapToConsumers } from '@app/areas/members/utils';

@Injectable()
export class OrderReviewsApiService {
  private readonly consumerService = inject(ConsumerService);
  private readonly orderReviewService = inject(OrderReviewService);
  private readonly questionService = inject(QuestionService);

  getReviews(): Observable<OrderReview[]> {
    return this.orderReviewService.findAll().pipe(map(mapToOrderReviews));
  }

  getReview(orderReviewId: string): Observable<OrderReview> {
    return this.orderReviewService.findUnique(orderReviewId).pipe(map(mapToOrderReview));
  }

  getQuestionsForReview(orderReviewId: string): Observable<Question[]> {
    return this.questionService.findByOrderReviewId(orderReviewId).pipe(map((q) => q.$values.map(mapToQuestion)));
  }

  getConsumerForReview(consumerId: number): Observable<Consumer> {
    return this.consumerService.findUnique(consumerId).pipe(map(mapToConsumer));
  }

  getConsumersForReviews(): Observable<Consumer[]> {
    return this.consumerService.findAll().pipe(map(mapToConsumers));
  }
}
