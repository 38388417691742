import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, tap, withLatestFrom } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { OrderReviewsApiService } from '@app/areas/reviews/services';
import { ConsumerActions, OrderReviewActions, RouterActions } from '@app/resources/ngrx/actions';
import { OrderReviewModuleActions } from '@app/areas/reviews/store/order-review-module.action';
import { selectConsumers } from '@app/resources/ngrx/selectors';

@Injectable()
export class OrderReviewModuleEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly orderReviewsApiService = inject(OrderReviewsApiService);

  loadOrderReviews = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrderReviewActions.loadOrderReviews),
        withLatestFrom(this.store.select(selectConsumers)),
        switchMap(([action, consumers]) => {
          if (!consumers?.length)
            return this.orderReviewsApiService.getConsumersForReviews().pipe(
              tap((consumers) =>
                this.store.dispatch(
                  ConsumerActions.upsertConsumers({
                    payload: { consumers },
                  })
                )
              ),
              switchMap(() => {
                return this.orderReviewsApiService.getReviews().pipe(
                  map((orderReviews) => OrderReviewActions.loadOrderReviewsSuccess({ payload: { orderReviews } })),
                  catchError((error: { message: string }) =>
                    of(OrderReviewActions.loadOrderReviewsError({ payload: { error: error.message } }))
                  )
                );
              })
            );

          return this.orderReviewsApiService.getReviews().pipe(
            map((orderReviews) => OrderReviewActions.loadOrderReviewsSuccess({ payload: { orderReviews } })),
            catchError((error: { message: string }) =>
              of(OrderReviewActions.loadOrderReviewsError({ payload: { error: error.message } }))
            )
          );
        })
      ),
    { dispatch: true }
  );

  navigateToExistingOrderReviewForm = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrderReviewModuleActions.navigateExistingOrderReview),
        tap(({ payload }) => {
          this.store.dispatch(
            RouterActions.navigateByUrlWithLocation({
              payload: {
                path: `reviews/${payload.orderReviewId}`,
              },
            })
          );
        })
      ),
    {
      dispatch: false,
    }
  );
}
