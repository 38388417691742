import { ConsumerState } from '@app/resources/ngrx/states';
import { consumerAdapter } from '@app/resources/ngrx/adapters';
import { createReducer, on } from '@ngrx/store';
import { ConsumerActions } from '@app/resources/ngrx/actions';
import * as fromConsumerAdapter from '@app/resources/ngrx/adapters';

export const initialConsumerModuleState: ConsumerState = consumerAdapter.getInitialState({
  selectedConsumerId: null,
  selectId: 0,
  hasLoaded: false,
});

export const _consumerReducer = createReducer(
  initialConsumerModuleState,
  on(ConsumerActions.loadConsumersSuccess, (state, { payload }) => {
    state = fromConsumerAdapter.consumerAdapter.removeAll({ ...state });
    return {
      ...fromConsumerAdapter.consumerAdapter.addMany(payload.consumers, state),
      hasLoaded: true,
    };
    return fromConsumerAdapter.consumerAdapter.addMany(payload.consumers, state);
  }),
  on(ConsumerActions.upsertConsumer, (state, { payload }) => {
    return fromConsumerAdapter.consumerAdapter.upsertOne(payload.consumer, state);
  }),
  on(ConsumerActions.upsertConsumers, (state, { payload }) => {
    return fromConsumerAdapter.consumerAdapter.upsertMany(payload.consumers, state);
  }),
  on(ConsumerActions.consumerById, (state, { payload }) => {
    return Object.assign({
      ...state,
      selectedConsumerId: payload.consumerId,
    });
  })
);
