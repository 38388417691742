<div class="order-review-questions-table" *ngIf="questions$ | async; let questions">
  <div class="shadow-2">
    <p-table
      id="order-review-questions-table"
      [columns]="columns"
      [value]="questions"
      styleClass="p-datatable-striped"
      data-cy="order-review-questions-table"
    >
      <ng-template pTemplate="caption">
        <div class="order-review-questions-table-header-wrapper">
          <div class="order-review-questions-table-header-title" data-cy="order-review-questions-table-header">
            <h4>Questions Asked</h4>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr class="order-review-question-table-row">
          <th class="order-review-questions-table-row-cell-0">Question</th>
          <th class="order-review-questions-table-row-cell-1">Answer</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <ng-container>
          <tr class="order-review-question-table-row">
            <td class="order-review-questions-table-row-cell-0">
              <span
                class="order-review-questions-table-row-column-value"
                [attr.data-cy]="'question-row-display-' + rowData['QuestionId']"
              >
                {{ rowData['Display'] }}
              </span>
            </td>
            <td class="order-review-questions-table-row-cell-1 question-status-type-column">
              <span
                *ngIf="isThumbsUp(rowData); else isThumbsDown"
                class="order-review-question-answer-value"
                [attr.data-cy]="'question-row-answer-' + rowData['OrderReviewQuestionId']"
              >
                <fa-icon [icon]="icons['faThumbsUp']" class="order-review-question-thumbs-up" />
                <fa-icon [icon]="icons['faThumbsDown']" style="margin-top: 4px" />
              </span>
              <ng-template #isThumbsDown>
                <span
                  class="order-review-question-answer-value"
                  [attr.data-cy]="'question-row-answer-' + rowData['OrderReviewQuestionId']"
                >
                  <fa-icon [icon]="icons['faThumbsUp']" />
                  <fa-icon
                    [icon]="icons['faThumbsDown']"
                    style="margin-top: 4px"
                    class="order-review-question-thumbs-down"
                  />
                </span>
              </ng-template>
            </td>
          </tr>
        </ng-container>
      </ng-template>
      <ng-template pTemplate="loadingicon">
        <div class="order-review-questions-table-loading-icon">
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </div>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="columns.length">No questions found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
