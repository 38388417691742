import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Report } from '@app/resources/services';

const sortReportById = (a: Report, b: Report) => {
  return a.ReportId - b.ReportId;
};

export const reportAdapter: EntityAdapter<Report> = createEntityAdapter<Report>({
  sortComparer: sortReportById,
  selectId: (e) => e?.ReportId ?? 0,
});

export const {
  selectIds: selectReportIds,
  selectEntities: selectReportEntities,
  selectAll: selectAllReports,
  selectTotal: reportsCount,
} = reportAdapter.getSelectors();
