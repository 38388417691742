// Angular Core Imports
import { Component, inject, input } from '@angular/core';
import { AsyncPipe, CurrencyPipe, DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';

// Third-Party Imports
import { DataViewModule } from 'primeng/dataview';
import { Button } from 'primeng/button';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { PanelModule } from 'primeng/panel';

// Shared and Application-Specific Imports
import { FONT_AWESOME_ICONS } from '@ep/shared';
import { OrderPayment } from '@app/resources/services';
import { TruncateGuidPipe } from '@shared/pipes';
import { RouterActions } from '@app/resources/ngrx/actions';
import { selectConsumerOrderPayments } from '@app/resources/ngrx/selectors';

@Component({
  selector: 'app-existing-member-recent-transactions-list',
  standalone: true,
  imports: [
    DataViewModule,
    NgForOf,
    CurrencyPipe,
    FaIconComponent,
    NgIf,
    Button,
    NgClass,
    TruncateGuidPipe,
    DatePipe,
    AsyncPipe,
    PanelModule,
  ],
  templateUrl: './existing-member-recent-transactions-list.component.html',
  styleUrl: './existing-member-recent-transactions-list.component.scss',
})
export class ExistingMemberRecentTransactionsListComponent {
  private readonly store = inject(Store);

  orderPayments = input.required<OrderPayment[]>();
  protected orderPayments$ = this.store.select(selectConsumerOrderPayments);

  protected icons = FONT_AWESOME_ICONS;

  protected isThumbsUp(payment: OrderPayment) {
    if (!!payment.Order?.Reviews?.length) {
      return payment.Order.Reviews[0].Score === 5;
    }
    return false;
  }

  protected navigateToOrder(payment: OrderPayment) {
    this.store.dispatch(
      RouterActions.navigateByUrlWithLocation({
        payload: {
          path: `transactions/${payment.OrderId}`,
        },
      })
    );
  }
}
