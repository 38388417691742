import { ReportState } from '@app/resources/ngrx/states';
import { reportAdapter } from '@app/resources/ngrx/adapters';
import { createReducer, on } from '@ngrx/store';
import { ReportActions } from '@app/resources/ngrx/actions';

export const initialReportModuleState: ReportState = reportAdapter.getInitialState({
  selectedReportId: null,
  selectId: 0,
  dailySalesReport: {
    date: null,
    reports: [],
  },
});

export const _reportReducer = createReducer(
  initialReportModuleState,
  on(ReportActions.loadReportsSuccess, (state, { payload }) => {
    state = reportAdapter.removeAll({ ...state });
    return reportAdapter.addMany(payload.reports, state);
  }),
  on(ReportActions.reportById, (state, { payload }) => {
    return Object.assign({
      ...state,
      selectedReportId: payload.reportId,
    });
  }),
  on(ReportActions.updateDailySalesReports, (state, { payload }) => {
    return Object.assign({
      ...state,
      dailySalesReport: {
        date: payload.date,
        reports: payload.reports,
      },
    });
  })
);
