<p-card class="order-review-display-card" data-cy="order-review-display-card">
  <ng-template pTemplate="header">
    <div id="order-review-display-card-header">
      <div id="order-review-display-card-header-title">
        <h1>Review - {{ review().CreatedDateTime | date : 'shortDate' }}</h1>
      </div>
    </div>
  </ng-template>
  <div class="order-review-display">
    <div class="order-review-display-row">
      <label class="order-review-display-row-label"> Consumer </label>
      <span> {{ consumer() }} </span>
    </div>
    <div class="order-review-display-row">
      <label class="order-review-display-row-label"> Total Amount </label>
      <span> {{ review().AmountTotal | currency }} </span>
    </div>
  </div>
</p-card>
