// Angular Core Modules
import { Component, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';

// Third Party Modules
import { Store } from '@ngrx/store';

// Application Modules
import { EpTableComponent, EPTableConfig } from '@ep/shared';

import { selectConsumersSortedByName } from '@app/resources/ngrx/selectors';
import { MemberModuleActions } from '@app/areas/members/store';
import { ConsumerTableDisplay } from '@app/areas/members/models/consumer-table-display.model';
import { mapToConsumerTableDisplays } from '@app/areas/members/utils';

// Utilities and Operators
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-members-table',
  standalone: true,
  imports: [EpTableComponent, AsyncPipe],
  template: `
    <div class="p-4">
      <app-ep-table [config]="config" [data]="members$ | async" data-cy="members-table" />
    </div>
  `,
})
export class MembersTableComponent {
  private readonly store = inject(Store);

  protected config: EPTableConfig<ConsumerTableDisplay> = {
    header: 'members',
    pagination: {
      rows: 10,
      showCurrentPageReport: true,
    },
    columns: [
      {
        header: 'First Name',
        field: 'NameFirst',
        columnType: 'string',
        defaultValue: 'VALUED',
      },
      {
        header: 'Last Name',
        field: 'NameLast',
        columnType: 'string',
        defaultValue: 'CUSTOMER',
      },
      {
        header: 'Last Seen',
        field: 'LastSeenDateTime',
        columnType: 'date',
        defaultValue: null,
      },
    ],
    style: {},
    globalFilterFields: ['NameFirst', 'NameLast'],
    actions: {
      rowClick: (member: ConsumerTableDisplay) => {
        this.store.dispatch(
          MemberModuleActions.navigateExistingMember({
            payload: { consumerId: member.ConsumerId },
          })
        );
      },
    },
  };

  protected members$ = this.store.select(selectConsumersSortedByName).pipe(map(mapToConsumerTableDisplays));
}
