import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, mergeMap, of, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { MembersApiService } from '@app/areas/members/services';
import { ConsumerActions, RouterActions } from '@app/resources/ngrx/actions';
import { MemberModuleActions } from '@app/areas/members/store/member-module.actions';

@Injectable()
export class MemberModuleEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly membersApiService = inject(MembersApiService);

  loadMembers = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConsumerActions.loadConsumers),
        exhaustMap(() =>
          this.membersApiService.getMembers().pipe(
            map((consumers) => ConsumerActions.loadConsumersSuccess({ payload: { consumers } })),
            catchError((error: { message: string }) =>
              of(ConsumerActions.loadConsumersError({ payload: { error: error.message } }))
            )
          )
        )
      ),
    { dispatch: true }
  );

  navigateToExistingMemberForm = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MemberModuleActions.navigateExistingMember),
        tap(({ payload }) => {
          this.store.dispatch(
            RouterActions.navigateByUrlWithLocation({
              payload: {
                path: `members/${payload.consumerId}`,
              },
            })
          );
        })
      ),
    {
      dispatch: false,
    }
  );
}
