import { Component } from '@angular/core';
import { ExistingReportDailySummaryTableComponent } from '@app/areas/reports/components/existing-report-daily-summary-table/existing-report-daily-summary-table.component';

@Component({
  selector: 'app-existing-report',
  standalone: true,
  imports: [ExistingReportDailySummaryTableComponent],
  templateUrl: './existing-report.component.html',
  styleUrl: './existing-report.component.scss',
})
export class ExistingReportComponent {}
