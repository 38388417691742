import { Component, input } from '@angular/core';
import { PanelModule } from 'primeng/panel';
import { PrimeTemplate } from 'primeng/api';
import { OrderPayment } from '@app/resources/services';
import { CurrencyPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-existing-transaction-payment-details',
  standalone: true,
  imports: [PanelModule, PrimeTemplate, CurrencyPipe, NgIf],
  templateUrl: './existing-transaction-payment-details.component.html',
  styleUrl: './existing-transaction-payment-details.component.scss',
})
export class ExistingTransactionPaymentDetailsComponent {
  payment = input.required<OrderPayment>();
}
