// Angular Core Modules
import { Component, input } from '@angular/core';
import { NgIf } from '@angular/common';

// Third Party Modules
import { CardModule } from 'primeng/card';
import { PrimeTemplate } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';

// Application Modules and Utils
import { Consumer } from '@app/resources/services';
import { ExistingMemberRecentTransactionsListComponent } from '@app/areas/members/components/existing-member-recent-transactions-list/existing-member-recent-transactions-list.component';
import { ExistingMemberInformationComponent } from '@app/areas/members/components/existing-member-information/existing-member-information.component';

@Component({
  selector: 'app-existing-member-display-card',
  standalone: true,
  imports: [
    CardModule,
    PrimeTemplate,
    AvatarModule,
    NgIf,
    ExistingMemberRecentTransactionsListComponent,
    ExistingMemberInformationComponent,
  ],
  templateUrl: './existing-member-display-card.component.html',
  styleUrl: './existing-member-display-card.component.scss',
})
export class ExistingMemberDisplayCardComponent {
  member = input.required<Consumer>();
}
