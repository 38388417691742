import {
  OrderReview,
  OrderReviewQuestion,
  OrderReviewQuestionResponse,
  OrderReviewResponse,
  OrderReviewsResponse,
} from '@app/resources/services';
import { mapToDeviceTracking, parseDeviceSettingsJson } from '@app/areas/admin/submodules/devices/utils';
import { isNotNullOrUndefined } from '@shared/utils';
import { ReviewTableDisplay } from '@app/areas/reviews/models/review-table-display.model';

const MAX_REVIEW_THUMBS_UP_QUESTION_RATING = 5;

export const mapOrderReviewStatusToValue = (orderReview: ReviewTableDisplay) => {
  return !isNotNullOrUndefined(orderReview.Score)
    ? 'Not Answered'
    : orderReview.Score === MAX_REVIEW_THUMBS_UP_QUESTION_RATING
    ? 'Great'
    : 'Poor';
};

export const getOrderReviewStatusSeverity = (orderReview: ReviewTableDisplay) => {
  return !isNotNullOrUndefined(orderReview.Score)
    ? 'warning'
    : orderReview.Score === MAX_REVIEW_THUMBS_UP_QUESTION_RATING
    ? 'success'
    : 'danger';
};

export const mapToOrderReviewQuestion = (data: OrderReviewQuestionResponse): OrderReviewQuestion => {
  if (typeof data.display === 'string') {
    return {
      OrderReviewQuestionId: data.orderReviewQuestionId,
      OrderReviewId: data.orderReviewId,
      QuestionId: data.questionId,
      Display: JSON.parse(data.display)['en-US'],
      Score: data.score,
    };
  }
  return {
    OrderReviewQuestionId: data.orderReviewQuestionId,
    OrderReviewId: data.orderReviewId,
    QuestionId: data.questionId,
    Display: data.display['en-US'],
    Score: data.score,
  };
};

export const mapToOrderReviews = (data: OrderReviewsResponse): OrderReview[] => {
  return data.$values.map(mapToOrderReview);
};

export const mapToOrderReview = (data: OrderReviewResponse): OrderReview => {
  return {
    OrderReviewId: data.orderReviewId,
    ConsumerId: data.consumerId,
    Score: data.score,
    CreatedDateTime: data.createdDateTime,
    UpdateDateTime: data.updateDateTime,
    AmountTotal: data.amountTotal,
    QuestionIds: data.questionIds.$values,
    Questions: [],
  };
};

export const mapToReviewTableDisplays = (data: OrderReview[] | null): ReviewTableDisplay[] | null => {
  return data ? data.map(mapToReviewTableDisplay) : null;
};

export const mapToReviewTableDisplay = (data: OrderReview): ReviewTableDisplay => {
  return {
    OrderReviewId: data.OrderReviewId,
    CreatedDateTime: data.CreatedDateTime,
    UpdateDateTime: data.UpdateDateTime,
    Score: data.Score,
    Consumer: data.Consumer ? `${data.Consumer.NameFirst} ${data.Consumer.NameLast}` : 'VALUED CUSTOMER',
  };
};
